module Greenfield.Client.AnalysisTool.Summary

open Shared
open Feliz
open Feliz.AgGrid

let view (sources: Map<string, SourceDescription>) =
    let dcSummaries =
        sources
        |> Seq.map (fun (KeyValue(name, description)) -> {|
            Name = name
            Summary = description.ClusterSummary
        |})
        |> Array.ofSeq

    Html.div [
        prop.className ThemeClass.Balham

        prop.children [
            AgGrid.grid [
                AgGrid.rowData dcSummaries
                AgGrid.defaultColDef [
                    ColumnDef.resizable true
                    ColumnDef.sortable true
                    ColumnDef.editable (fun _ -> false)
                    unbox ("flex", 1)
                ]
                AgGrid.domLayout AutoHeight
                AgGrid.columnDefs [
                    ColumnDef.create<string> [
                        ColumnDef.filter RowFilter.Text
                        ColumnDef.headerName "DC"
                        ColumnDef.valueGetter (fun x -> x.Name)
                    ]
                    ColumnDef.create<float> [
                        ColumnDef.filter RowFilter.Number
                        ColumnDef.headerName "Total Volume"
                        ColumnDef.columnType ColumnType.NumericColumn
                        ColumnDef.valueFormatter (fun x _ -> sprintf "%.2f" x)
                        ColumnDef.valueGetter (fun x -> x.Summary.TotalWeight)
                        ColumnDef.columnGroupShow true
                    ]
                    ColumnDef.create<int> [
                        ColumnDef.filter RowFilter.Number
                        ColumnDef.headerName "Cities Served"
                        ColumnDef.columnType ColumnType.NumericColumn
                        ColumnDef.valueGetter (fun x ->
                            x.Summary.TotalDeliveries
                        )
                        ColumnDef.columnGroupShow true
                    ]
                    ColumnDef.create<float> [
                        ColumnDef.filter RowFilter.Number
                        ColumnDef.headerName "Average Travel Distance (km)"
                        ColumnDef.columnType ColumnType.NumericColumn
                        ColumnDef.valueFormatter (fun x _ ->
                            sprintf "%.0f" (x / 1000.0)
                        )
                        ColumnDef.valueGetter (fun x ->
                            x.Summary.AverageTravelDistance
                        )
                        ColumnDef.columnGroupShow true
                    ]
                    ColumnDef.create<string> [
                        ColumnDef.filter RowFilter.Text
                        ColumnDef.headerName "Highest Volume Destination"
                        ColumnDef.valueGetter (fun x ->
                            $"{x.Summary.HighestVolumeDestination.City}, {x.Summary.HighestVolumeDestination.State}"
                        )
                        ColumnDef.columnGroupShow true
                    ]
                    ColumnDef.create<string> [
                        ColumnDef.filter RowFilter.Text
                        ColumnDef.headerName "Most Frequent Destination"
                        ColumnDef.valueGetter (fun x ->
                            $"{x.Summary.MostFrequentDestination.City}, {x.Summary.MostFrequentDestination.State}"
                        )
                        ColumnDef.columnGroupShow true
                    ]
                ]
            ]
        ]
    ]