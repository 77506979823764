module Greenfield.Client.App

open Elmish
open Elmish.React
open Fable.Core.JsInterop
open Feliz
open Feliz.Bulma
open Shared
open Browser

open Greenfield.Domain

importSideEffects "./scss/main.scss"
importSideEffects "./js/leaflet-workaround.js"

type Msg =
    | IndexMsg of Index.Msg
    | AnalysisToolMsg of AnalysisTool.Root.Msg
    | SwitchToAnalysisTool of Destination array
    | SwitchToIndex

type Model =
    | Index of Index.Model
    | AnalysisTool of AnalysisTool.Root.Model

let init () =
    let indexModel, indexCmd = Index.init ()

    Index indexModel, Cmd.map IndexMsg indexCmd

let update msg model =
    match model, msg with
    | Index subModel, IndexMsg msg ->
        let newSubModel, newSubCmd, externalMsg = Index.update msg subModel

        let extraCmd =
            match externalMsg with
            | Index.ExternalMsg.None -> Cmd.none
            | Index.ExternalMsg.LoadAnalysisTool destinations ->
                Cmd.ofMsg (SwitchToAnalysisTool destinations)

        Index newSubModel,
        Cmd.batch [
            Cmd.map IndexMsg newSubCmd
            extraCmd
        ]

    | Index _, SwitchToAnalysisTool destinations ->
        let analysisToolModel, analysisToolCmd = AnalysisTool.Root.init destinations

        AnalysisTool analysisToolModel, Cmd.map AnalysisToolMsg analysisToolCmd

    | AnalysisTool subModel, AnalysisToolMsg msg ->
        let newSubModel, newSubCmd = AnalysisTool.Root.update msg subModel

        AnalysisTool newSubModel, Cmd.map AnalysisToolMsg newSubCmd

    | AnalysisTool _, SwitchToIndex ->
        let indexModel, indexCmd = Index.init ()

        Index indexModel, Cmd.map IndexMsg indexCmd
    // Invalid state transition
    | _ ->
        model, Cmd.none

let view model dispatch =
    let pageContent =
        match model with
        | Index indexModel -> Index.view indexModel (IndexMsg >> dispatch)
        | AnalysisTool analysisToolModel ->
            AnalysisTool.Root.view analysisToolModel (AnalysisToolMsg >> dispatch)

    React.fragment [
        Bulma.navbar [
            navbar.isFixedTop
            navbar.hasShadow

            prop.children [
                Bulma.navbarBrand.div [
                    Bulma.navbarItem.div [
                        prop.style [
                            style.cursor.pointer
                        ]
                        prop.onClick (fun _ ->
                            dispatch SwitchToIndex
                        )
                        prop.children [
                            Bulma.image [
                                image.is64x64
                                prop.children [
                                    Html.img [
                                        prop.className "navbar-logo"
                                        prop.alt "SDI Logo"
                                        prop.src "/sdilogo.png"
                                    ]
                                ]
                            ]

                            Bulma.text.div [
                                spacing.ml3
                                size.isSize2
                                // text.isUppercase
                                text.hasTextWeightSemibold
                                prop.text "Optimal Site App"
                            ]
                        ]
                    ]
                ]
                Bulma.navbarMenu [
                    Bulma.navbarEnd.div [
                        Bulma.navbarItem.div [
                            prop.style [
                                style.cursor.pointer
                            ]
                            prop.onClick (fun _ ->
                                dispatch SwitchToIndex
                            )
                            prop.children [
                                Bulma.button.button [
                                    match model with
                                    | Index indexModel -> Bulma.helpers.isHidden
                                    | AnalysisTool analysisToolModel -> helpers.isClickable
                                    color.isPrimary
                                    button.isMedium
                                    prop.children [
                                        Bulma.icon [
                                            Html.i [
                                                prop.className "fa-solid fa-arrow-left"
                                            ]
                                        ]
                                        Html.span [
                                            prop.text "Back"
                                        ]
                                    ]
                                ]
                            ]
                        ]
                        Bulma.navbarItem.div [
                            Html.a [
                                prop.style [
                                    style.cursor.pointer
                                ]
                                prop.children [
                                    Bulma.button.button [
                                        color.isPrimary
                                        button.isMedium
                                        prop.children [
                                            Bulma.icon [
                                                Html.i [
                                                    prop.className "fa-sharp fa-solid fa-house"
                                                ]
                                            ]
                                        ]
                                    ]
                                ]
                                prop.target "_blank"
                                prop.href "https://simulationdynamics.com/"
                            ]
                        ]
                        Bulma.navbarItem.div [
                            Html.a [
                                prop.style [
                                    style.cursor.pointer
                                ]
                                prop.children [
                                    Bulma.button.button [
                                        color.isPrimary
                                        button.isMedium
                                        prop.children [
                                            Bulma.icon [
                                                Html.i [
                                                    prop.className "fa-brands fa-linkedin"
                                                ]
                                            ]
                                        ]
                                    ]
                                ]
                                prop.target "_blank"
                                prop.href "https://www.linkedin.com/company/simulation-dynamics-inc/"
                            ]
                        ]
                        Bulma.navbarItem.div [
                            Html.a [
                                prop.style [
                                    style.cursor.pointer
                                ]
                                prop.children [
                                    Bulma.button.button [
                                        color.isPrimary
                                        button.isMedium
                                        prop.children [
                                            Bulma.icon [
                                                Html.i [
                                                    prop.className "fa-regular fa-envelope"
                                                ]
                                            ]
                                        ]
                                    ]
                                ]
                                prop.target "_blank"
                                prop.href "https://simulationdynamics.com/contact-us"
                            ]
                        ]
                    ]
                ]
            ]
        ]

        pageContent
    ]

#if DEBUG
open Elmish.Debug
open Elmish.HMR
#endif

Program.mkProgram init update view
#if DEBUG
|> Program.withConsoleTrace
#endif
|> Program.withReactSynchronous "elmish-app"
#if DEBUG
|> Program.withDebugger
#endif
|> Program.run