module Greenfield.Client.AnalysisTool.Map

open Shared
open Feliz
open Feliz.Bulma
open Fable.Core
open Fable.Core.JsInterop
open Feliz.ReactLeaflet
open System
open Greenfield.Domain

// Some additional binding that are missing from the ReactLeaflet bindings

type IoverlayProp =
    interface end

[<Erase>]
type layersControl =

    static member inline overlay (properties: IoverlayProp list) =
        let layersControl = import "LayersControl" "react-leaflet"
        let overlay = layersControl?Overlay

        Interop.reactApi.createElement (overlay, createObj !!properties)

    static member inline children (elements: ReactElement list) = unbox<ILayersControlProp> (prop.children elements)

module layersControl =

    [<Erase>]
    type overlay =

        static member inline name (name: string) = unbox<IoverlayProp>("name", name)
        static member inline ``checked`` (value: bool) = unbox<IoverlayProp>("checked", value)
        static member inline children (elements: ReactElement list) = unbox<IoverlayProp> (prop.children elements)


let private leafet: Leaflet.IExports = importDefault "leaflet"

let private center = (39.83, -98.58)

let private colors = [
    "rgb(255, 127, 14)"
    "rgb(31, 119, 180)"
    "rgb(44, 160, 44)"
    "rgb(214, 39, 40)"
    "rgb(148, 103, 189)"
    "rgb(140, 86, 75)"
    "rgb(227, 119, 194)"
    "rgb(127, 127, 127)"
    "rgb(188, 189, 34)"
    "rgb(23, 190, 207)"
    "rgb(31, 119, 180)"
]

let private warehouseMarkers (sources: Map<string, SourceDescription>) =

    let markers =
        sources
        |> Seq.sortBy (fun (KeyValue(name, _)) -> name)
        |> Seq.mapi (fun index (KeyValue(name, description)) ->
            let positon =
                description.Coordinates.Latitude,
                description.Coordinates.Longitude

            let color = colors.[index % colors.Length]

            let icon =
                leafet.DivIcon.Create(
                    jsOptions<Leaflet.DivIconOptions> (fun o ->
                        o.className <- Some "my-div-icon"

                        o.html <-
                            Some
                                !^ $"""<div class='marker-icon'>
    <!--
        This div protect the stack icon from being deformed
        by the flex display of 'marker-icon'
    -->
  <div>
    <span class="fa-stack fa-lg" style='color: {color};'>
    <i class="fas fa-square fa-stack-2x"></i>
    <i class='fas fa-warehouse fa-stack-1x fa-inverse'></i>
  </span>
  </div>
</div>"""

                    )
                )

            ReactLeaflet.marker [
                marker.position positon
                marker.icon icon
                marker.children [
                    ReactLeaflet.tooltip [
                        tooltip.direction Direction.Top
                        tooltip.children [
                            Bulma.text.div [
                                size.isSize6
                                prop.children [
                                    Html.div [
                                        Html.span "("
                                        Html.span (
                                            Math.Round(
                                                description.Coordinates.Latitude,
                                                4
                                            )
                                        )
                                        Html.span "°, "
                                        Html.span (
                                            Math.Round(
                                                description.Coordinates.Longitude,
                                                4
                                            )
                                        )
                                        Html.span "°)"
                                    ]
                                    Html.div name
                                ]
                            ]
                        ]
                    ]
                ]
            ]

        )
        |> Seq.toList

    ReactLeaflet.layerGroup [
        layerGroup.children markers
    ]

let private renderDestinations (destinations: Destination array) =

    let log10 x = log x / log 10.

    let destinations =
        destinations
        |> Array.groupBy (fun dest -> dest.Location.Coordinates)
        |> Array.map (fun (_, destinations) ->
            let totalWeight =
                destinations |> Array.sumBy (fun d -> d.WeightingFactor)

            {
                destinations.[0] with
                    WeightingFactor = log10 (1. + totalWeight)
            }
        )

    let largestWeight =
        destinations
        |> Array.map (fun dest -> dest.WeightingFactor)
        |> Array.max

    let groupedDestinations =
        destinations |> Array.groupBy (fun d -> d.Assignment) |> Array.map snd

    groupedDestinations
    |> Array.sortBy (fun destinations -> destinations.[0].Assignment)
    |> Array.mapi (fun groupIndex destinations ->
        let color = colors.[groupIndex % colors.Length]

        let destinationMarkers =
            destinations
            |> Array.map (fun destination ->
                let weightedRadius =
                    destination.WeightingFactor * 10. / largestWeight

                let center =
                    destination.Location.Coordinates.Latitude,
                    destination.Location.Coordinates.Longitude

                // This code generates a volune circle + a dot in the center
                // It doesn't look that good right now, but I left the code here
                // if you prefer to use that version instead
                // ReactLeaflet.featureGroup [
                //     featureGroup.children [
                //         ReactLeaflet.circle [
                //             circle.center center
                //             circle.color "black"
                //             circle.weight 4
                //         ]
                //         ReactLeaflet.circleMarker [
                //             circleMarker.center center
                //             circleMarker.radius weightedRadius
                //             circleMarker.color color
                //             circleMarker.weight 1
                //         ]
                //     ]
                // ]
                ReactLeaflet.circleMarker [
                    circleMarker.center center
                    circleMarker.radius weightedRadius
                    circleMarker.color color
                    circleMarker.weight 1
                    circleMarker.children [
                        ReactLeaflet.tooltip [
                            tooltip.direction Direction.Top
                            tooltip.children [
                                Html.span destination.Location.CityState.City
                                Html.span ", "
                                Html.span destination.Location.CityState.State
                            ]
                        ]
                    ]
                ]
            )
            |> Array.toList

        ReactLeaflet.layerGroup [
            layerGroup.children destinationMarkers
        ]
    )
    |> Array.toList
    // The double layerGroup is forcing React to re-render the circle on data changes
    |> (fun children ->
        ReactLeaflet.layerGroup [
            layerGroup.children children
        ]
    )


[<ReactComponent>]
let View
    (destinations: Destination array)
    (sources: Map<string, SourceDescription> option)
    =

    ReactLeaflet.mapContainer [
        mapContainer.style [
            style.height 500
        ]
        mapContainer.center center
        mapContainer.zoom 4
        mapContainer.attributionControl false
        mapContainer.zoomControl false
        mapContainer.children [
            ReactLeaflet.tileLayer [
                tileLayer.url
                    "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            ]

            ReactLeaflet.layersControl [
                layersControl.position Leaflet.ControlPosition.Topright
                layersControl.children [
                    layersControl.overlay [
                        layersControl.overlay.name "Warehouses"
                        layersControl.overlay.``checked`` true
                        layersControl.overlay.children [
                            match sources with
                            | Some sources -> warehouseMarkers sources
                            | None -> null
                        ]
                    ]
                    layersControl.overlay [
                        layersControl.overlay.name "Destination volume"
                        layersControl.overlay.``checked`` true
                        layersControl.overlay.children [
                            renderDestinations destinations
                        ]
                    ]
                ]
            ]
        ]
    ]
