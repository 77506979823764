module Greenfield.Client.AnalysisTool.Root

open Elmish
open Shared
open Feliz
open Feliz.Bulma
open Feliz.Plotly
open System
open Greenfield.Client
open Thoth.Elmish
open Greenfield.Domain

type Msg =
    | StartAnalysis
    | AnalysisComplete of Response
    | PinnedDCCardMsg of PinnedDCCard.Msg
    | AddPinnedDC of CityState * Coordinates
    | RemovePinnedDC of Guid
    | DebouncerSelfMsg of Debouncer.SelfMessage<Msg>
    | ChangeNumberOfDCs of int

type Model = {
    Destinations: Destination array
    AnalysisResult: Types.AnalysisResult option
    PinnedDCs: Types.PinnedDC list
    PinnedDCCard: PinnedDCCard.Model
    Debouncer: Debouncer.State
    NumberOfDCs: int
    IsLoading: bool
}

let init destinations =
    let pinnedDCModel, pinnedDCCmd = PinnedDCCard.init ()

    {
        Destinations = destinations
        AnalysisResult = None
        PinnedDCs = []
        PinnedDCCard = pinnedDCModel
        Debouncer = Debouncer.create ()
        NumberOfDCs = 1
        IsLoading = false
    },
    Cmd.batch [
        Cmd.ofMsg StartAnalysis
        Cmd.map PinnedDCCardMsg pinnedDCCmd
    ]

let private updateDebouncerForStartAnalysis currentState =
    currentState
    |> Debouncer.bounce
        (TimeSpan.FromSeconds 0.75)
        "start_analysis"
        StartAnalysis

let update msg model =
    match msg with
    | StartAnalysis ->
        if model.IsLoading then
            model, Cmd.none
        else
            let request = {
                ClustersNumber = model.NumberOfDCs
                Destinations = model.Destinations
                PinnedDCs =
                    model.PinnedDCs
                    |> List.map (fun x -> x.Location.Coordinates)
                    |> Array.ofList
            }

            let cmd =
                Cmd.OfAsync.perform
                    EndPoints.greenfieldApi.identifySources
                    request
                    AnalysisComplete

            {
                model with
                    IsLoading = true
            },
            cmd

    | ChangeNumberOfDCs n ->
        let n =
            if n < model.PinnedDCs.Length then model.PinnedDCs.Length
            else if n < 1 then 1
            else if n > 10 then 10
            else n

        let (debouncerModel, debouncerCmd) =
            updateDebouncerForStartAnalysis model.Debouncer

        {
            model with
                NumberOfDCs = n
                Debouncer = debouncerModel
        },
        Cmd.map DebouncerSelfMsg debouncerCmd

    | AnalysisComplete response ->
        let analysisResult : Types.AnalysisResult = {
            Sources = response.Sources
        }

        {
            model with
                AnalysisResult = Some analysisResult
                Destinations = response.Destinations
                IsLoading = false
        },
        Cmd.none

    | PinnedDCCardMsg msg ->
        let pinnedDCModel, pinnedDCCmd, externalMsg =
            PinnedDCCard.update msg model.PinnedDCCard

        let extraCmd =
            match externalMsg with
            | PinnedDCCard.ExternalMsg.None -> Cmd.none
            | PinnedDCCard.ExternalMsg.NewPinnedDC(cityState, coords) ->
                Cmd.ofMsg (AddPinnedDC(cityState, coords))
            | PinnedDCCard.ExternalMsg.RemovePinnedDC id ->
                Cmd.ofMsg (RemovePinnedDC id)

        {
            model with
                PinnedDCCard = pinnedDCModel
        },
        Cmd.batch [
            Cmd.map PinnedDCCardMsg pinnedDCCmd
            extraCmd
        ]

    | AddPinnedDC(cityState, coords) ->
        let newPinnedDC: Types.PinnedDC = {
            Id = Guid.NewGuid()
            Location = {
                CityState = cityState
                Coordinates = coords
            }
        }

        let (debouncerModel, debouncerCmd) =
            updateDebouncerForStartAnalysis model.Debouncer

        {
            model with
                PinnedDCs =
                    model.PinnedDCs
                    @ [
                        newPinnedDC
                    ]
                Debouncer = debouncerModel
        },
        Cmd.map DebouncerSelfMsg debouncerCmd

    | RemovePinnedDC id ->
        let (debouncerModel, debouncerCmd) =
            updateDebouncerForStartAnalysis model.Debouncer

        {
            model with
                PinnedDCs = model.PinnedDCs |> List.filter (fun x -> x.Id <> id)
                Debouncer = debouncerModel
        },
        Cmd.map DebouncerSelfMsg debouncerCmd

    | DebouncerSelfMsg debouncerMsg ->
        let (debouncerModel, debouncerCmd) =
            Debouncer.update debouncerMsg model.Debouncer

        {
            model with
                Debouncer = debouncerModel
        },
        debouncerCmd

let view model dispatch =
    Bulma.text.div [
        spacing.mr3
        spacing.mt3

        prop.children [
            Bulma.columns [
                Bulma.column [
                    column.is5Desktop
                    column.is4Widescreen
                    column.is5
                    spacing.ml3
                    prop.children [

                        Bulma.card [
                            Bulma.cardHeader [
                                Bulma.cardHeaderTitle.p [
                                    color.hasTextWhite
                                    prop.text "Total Number of DCs"
                                ]
                            ]
                            Bulma.cardContent [
                                Bulma.control.div [
                                    Bulma.input.number [
                                        prop.disabled (model.IsLoading)
                                        prop.placeholder "1"
                                        prop.value model.NumberOfDCs
                                        prop.onChange (fun value ->
                                            dispatch (ChangeNumberOfDCs value)
                                        )
                                    ]
                                ]
                            ]
                        ]

                        Html.br []

                        PinnedDCCard.view
                            model.PinnedDCs
                            model.NumberOfDCs
                            model.PinnedDCCard
                            (PinnedDCCardMsg >> dispatch)
                    ]
                ]

                Bulma.column [
                    prop.children [
                        Html.div [
                            prop.children [
                                match model.AnalysisResult with
                                | Some analysisResult ->
                                    React.fragment [
                                        Map.View model.Destinations (Some analysisResult.Sources)

                                        Html.br []

                                        Summary.view analysisResult.Sources
                                    ]
                                | None ->
                                    Map.View model.Destinations None
                            ]
                        ]
                    ]
                ]
            ]

        ]
    ]