namespace Shared

open Greenfield.Domain

type ClusterSummary = {
    TotalWeight: float
    TotalDeliveries: int
    AverageTravelDistance: float
    MostFrequentDestination: CityState
    HighestVolumeDestination: CityState
    }

type SourceDescription = {
    Coordinates: Coordinates
    ClusterSummary: ClusterSummary
    }

type Request = {
    ClustersNumber: int
    Destinations: Destination []
    PinnedDCs: Coordinates []
    }

type Response = {
    Sources: Map<string, SourceDescription>
    Destinations: Destination []
    }

type DataFile = {
    Content: byte[]
    }

type ErrorDetail =
    | MissingCity
    | MissingState
    | MissingVolume
    | NegativeVolume
    | MalformedRow of string

type ParsingError = { RowNumber:int; ErrorDetails : ErrorDetail }

type QueriedDestinations =
    {
        DestinationsFound: Destination []
        DestinationsNotFound: CityState []
        FileReadErrors: ParsingError []
    }

type LatLongSource =
    | FromCache of CityState * Coordinates
    | FromGoogle of CityState * Coordinates

module Route =
    let builder typeName methodName =
        sprintf "/api/%s/%s" typeName methodName

type IGreenfieldApi = {
    uploadFile: (string * DataFile) -> Async<Result<QueriedDestinations, string>>
    identifySources: Request -> Async<Response>
    getLocation: CityState -> Async<Result<(CityState * Coordinates), string>>
    getDemoLocations: unit -> Async<Destination []>
    }
