namespace Greenfield.Client

open Fable.Remoting.Client
open Shared

[<RequireQualifiedAccess>]
module EndPoints =

    let greenfieldApi =
        Remoting.createApi ()
        |> Remoting.withRouteBuilder Route.builder
        |> Remoting.buildProxy<IGreenfieldApi>
