module Greenfield.Client.AnalysisTool.PinnedDCCard

open Elmish
open Shared
open Feliz
open Feliz.Bulma
open Feliz.Plotly
open System
open Fable.Core.JsInterop
open Greenfield.Client
open Greenfield.Domain

type Msg =
    | ChangeCity of string
    | ChangeState of string
    | GetCoords
    | GetCoordsResult of Result<CityState * Coordinates, string>
    | RemovePinnedDC of Guid

type Model = {
    City: string
    State: string
    ErrorMessage: string
    IsLoading: bool
}

[<RequireQualifiedAccess>]
type ExternalMsg =
    | None
    | NewPinnedDC of CityState * Coordinates
    | RemovePinnedDC of Guid

let init () =
    {
        City = ""
        State = ""
        ErrorMessage = ""
        IsLoading = false
    },
    Cmd.none

let update msg (model: Model) =
    match msg with
    | ChangeCity city ->
        {
            model with
                City = city
        },
        Cmd.none,
        ExternalMsg.None

    | ChangeState state ->
        {
            model with
                State = state
        },
        Cmd.none,
        ExternalMsg.None

    | GetCoords ->
        if model.IsLoading then
            model, Cmd.none, ExternalMsg.None
        else
            let args = {
                City = model.City
                State = model.State
            }

            let cmd =
                Cmd.OfAsync.perform
                    EndPoints.greenfieldApi.getLocation
                    args
                    GetCoordsResult

            let newModel = {
                model with
                    IsLoading = true
            }

            newModel, cmd, ExternalMsg.None

    | GetCoordsResult(Ok(cityState, coords)) ->
        let newModel = {
            model with
                City = ""
                State = ""
                IsLoading = false
                ErrorMessage = ""
        }

        newModel, Cmd.none, ExternalMsg.NewPinnedDC(cityState, coords)

    | GetCoordsResult(Error error) ->
        let newModel = {
            model with
                IsLoading = false
                ErrorMessage = error
        }

        newModel, Cmd.none, ExternalMsg.None

    | RemovePinnedDC id ->
        model, Cmd.none, ExternalMsg.RemovePinnedDC id

let private headers =
    Bulma.columns [
        columns.isMobile
        spacing.mb0
        prop.children [
            Bulma.column [
                text.hasTextWeightBold
                prop.text "City"
            ]
            Bulma.column [
                text.hasTextWeightBold
                prop.text "State"
            ]
            Bulma.column [
                column.is2
            ]
        ]
    ]

[<ReactComponent>]
let private PinnedDCRow (info: Types.PinnedDC) dispatch =
    let selfRef = React.useRef None


    React.useEffectOnce(fun () ->
        let opts =
            createObj [
                "behavior" ==> "smooth"
                "block" ==> "end"
                "inline" ==> "nearest"
            ]

        selfRef.current.Value?scrollIntoView(opts)
    )

    Bulma.columns [
        columns.isVCentered
        columns.isMobile
        prop.ref selfRef
        prop.children [
            Bulma.column [
                prop.text info.Location.CityState.City
            ]
            Bulma.column [
                prop.text info.Location.CityState.State
            ]
            Bulma.column [
                column.isNarrow
                prop.style [
                    style.paddingRight (length.rem 1.5)
                ]
                prop.children [
                    Bulma.button.button [
                        color.isDanger
                        button.isOutlined
                        button.isSmall
                        prop.onClick (fun _ -> dispatch (RemovePinnedDC info.Id))
                        prop.children [
                            Bulma.icon [
                                icon.isSmall
                                prop.children [
                                    Html.i [
                                        prop.className
                                            "fas fa-lg fa-trash"
                                    ]
                                ]
                            ]
                        ]
                    ]
                ]
            ]
        ]
    ]

let private body (pinnedDCs: Types.PinnedDC list) dispatch =
    Html.div [
        prop.className "pinned-dcs-scrollable-list"
        prop.children [
            for pinnedDC in pinnedDCs do
                PinnedDCRow pinnedDC dispatch
        ]
    ]

let private footer (numberOfDCs: int) (pinnedDCs: Types.PinnedDC list) model dispatch =
    // If at least one of the input is not empty, then we can submit
    let preventSubmit =
        // TODO: Rewrite using moore's algebra
        // I was not able to get this to work with the moore's algebra
        // I don't know if this is because I am making an obious mistake
        // or if the code by Fable is wrong
        // Note: Fable generates a ternary operator instead of keep the or expression

        if pinnedDCs.Length >= (numberOfDCs) then
            true
        else if not (String.IsNullOrWhiteSpace model.City) then
            false
        else if not (String.IsNullOrWhiteSpace model.State) then
            false
        else
            true

    let iconClass =
        if model.IsLoading then
            "fas fa-spinner fa-spin"
        else
            "fas fa-plus"

    React.fragment [
        Bulma.columns [
            prop.className "table-like-footer is-marginless"
            columns.isMobile
            prop.children [

                Bulma.column [
                    prop.style [
                        style.paddingLeft 0
                    ]
                    prop.children [
                        Bulma.field.div [
                            Bulma.control.div [
                                Bulma.input.text [
                                    prop.placeholder "City"
                                    prop.value model.City
                                    prop.onChange (fun value ->
                                        dispatch (ChangeCity value)
                                    )
                                ]
                            ]
                        ]
                    ]
                ]
                Bulma.column [
                    prop.style [
                        style.paddingLeft 0
                        style.paddingRight 0
                    ]
                    prop.children [
                        Bulma.control.div [
                            Bulma.input.text [
                                prop.placeholder "State"
                                prop.value model.State
                                prop.onChange (fun value ->
                                    dispatch (ChangeState value)
                                )
                            ]
                        ]
                    ]
                ]
                Bulma.column [
                    column.isNarrow
                    prop.style [
                        style.paddingRight (length.rem 0)
                    ]
                    prop.children [
                        Bulma.field.div [
                            Bulma.control.div [
                                Bulma.button.button [
                                    color.isPrimary
                                    prop.disabled preventSubmit
                                    prop.onClick (fun _ -> dispatch GetCoords)
                                    prop.children [
                                        Bulma.icon [
                                            icon.isSmall
                                            prop.children [
                                                Html.i [
                                                    prop.className iconClass
                                                ]
                                            ]
                                        ]
                                    ]
                                ]
                            ]
                        ]
                    ]
                ]
            ]
        ]

        Bulma.help [
            color.isDanger
            // If the error message is empty, then we use the empty space character
            // to not have a layout jump when displaying the error message
            // if model.ErrorMessage = "" then
            //     prop.dangerouslySetInnerHTML "&#10240;"
            //     prop.style [
            //         style.visibility.hidden
            //     ]
            // else
            prop.text model.ErrorMessage
        ]
    ]

let view (pinnedDCs: Types.PinnedDC list) (numberOfDCs: int) model dispatch =
    Bulma.card [
        Bulma.cardHeader [
            Bulma.cardHeaderTitle.p [
                color.hasTextWhite
                prop.text "Pinned DCs"
            ]
        ]
        Bulma.cardContent [

            headers
            body pinnedDCs dispatch
            footer numberOfDCs pinnedDCs model dispatch

        ]
    ]