module Greenfield.Client.Index

open Elmish
open Fable.Remoting.Client
open Shared
open Feliz
open Feliz.Bulma

open Greenfield.Domain

type Msg =
    | UploadFile of Browser.Types.File list
    | UseDemoData
    | GotDestinations of Destination[]
    | FileUploaded of Result<QueriedDestinations, string>
    | ContinueWithValidRows

type Model =
    /// <summary>
    /// Default state, waiting for the user to select a file or use demo data.
    /// </summary>
    | Idle
    ///// <summary>
    ///// User selected an invalid file.
    ///// </summary>
    // | InvalidFile of string
    /// <summary>
    /// User selected a valid file but the server reported an error.
    /// </summary>
    | FileFailed of string
    /// <summary>
    /// User clicked the "Use demo data" button and we are waiting for the server response
    /// </summary>
    | FetchingDemoData
    /// <summary>
    /// User selected a valid file and we are waiting for the server response.
    /// </summary>
    | UploadingFile
    | FileReturnedWithWarnings of QueriedDestinations

[<RequireQualifiedAccess>]
type ExternalMsg =
    | None
    | LoadAnalysisTool of Destination[]

let init () = Idle, Cmd.none

let update msg model =
    match msg with
    | UseDemoData ->
        match model with
        | Idle
        | FileReturnedWithWarnings _
        | FileFailed _ ->
            let cmd =
                Cmd.OfAsync.perform
                    EndPoints.greenfieldApi.getDemoLocations
                    ()
                    GotDestinations

            FetchingDemoData, cmd, ExternalMsg.None

        | FetchingDemoData
        | UploadingFile -> model, Cmd.none, ExternalMsg.None

    | UploadFile files ->
        match model with
        | Idle
        | FileReturnedWithWarnings _
        | FileFailed _ ->
            let upload () =
                async {
                    // TODO do we have 0 files, 2.. files?
                    let file = files.[0]

                    if file.name.EndsWith(".csv") then
                        let! contents = file.ReadAsByteArray()

                        let request = {
                            Content = contents
                        }

                        return!
                            EndPoints.greenfieldApi.uploadFile (
                                file.name,
                                request
                            )
                    else
                        return Error "Incorrect File Type: .csv required"
                }

            let cmd = Cmd.OfAsync.perform upload () FileUploaded

            UploadingFile, cmd, ExternalMsg.None

        | FetchingDemoData
        | UploadingFile -> model, Cmd.none, ExternalMsg.None

    | FileUploaded(Error errorMessage) ->
        FileFailed errorMessage, Cmd.none, ExternalMsg.None

    | FileUploaded(Ok serverResponse) ->
        if serverResponse.FileReadErrors.Length <> 0
            || serverResponse.DestinationsNotFound.Length <> 0 then
            FileReturnedWithWarnings serverResponse, Cmd.none, ExternalMsg.None
        else
            model,
            Cmd.none,
            ExternalMsg.LoadAnalysisTool serverResponse.DestinationsFound

    | GotDestinations destinations ->
        model, Cmd.none, ExternalMsg.LoadAnalysisTool destinations

    | ContinueWithValidRows ->
        match model with
        | FileReturnedWithWarnings serverResponse ->
            model,
            Cmd.none,
            ExternalMsg.LoadAnalysisTool serverResponse.DestinationsFound

        | Idle
        | FetchingDemoData
        | UploadingFile
        | FileFailed _ -> model, Cmd.none, ExternalMsg.None

let private useDemoDateButton model dispatch =
    Bulma.field.div [
        field.hasAddons
        field.hasAddonsCentered

        prop.children [
            Bulma.control.div [
                prop.children [
                    Bulma.button.button [
                        color.isPrimary
                        button.isLarge

                        match model with
                        | FetchingDemoData -> button.isLoading
                        | UploadingFile -> prop.disabled true
                        | Idle
                        | FileReturnedWithWarnings _
                        | FileFailed _ -> ()

                        prop.onClick (fun _ -> dispatch UseDemoData)
                        prop.children [
                            Bulma.icon [
                                Html.i [
                                    prop.className "fas fa-file-csv"
                                ]
                            ]
                            Html.span [
                                prop.text "Use demo data"
                            ]
                        ]
                    ]
                ]
            ]
        ]
    ]

let private uploadFileButton model dispatch =
    Bulma.field.div [
        field.hasAddons
        field.hasAddonsCentered
        prop.style [
            // This specific margin value make it so that the top and bottom
            // of the "or" text is at the same from both buttons.
            style.marginTop (length.rem 1.25)
        ]
        prop.children [
            Bulma.control.div [
                Bulma.file [
                    file.isLarge
                    color.isPrimary

                    prop.children [
                        // We don't use the standard Bulma file upload
                        // elements because it does support loading animation
                        // By making the input a child of the button we get
                        // the visual/animation of the button and file picker behaviour
                        Bulma.fileLabel.label [

                            Bulma.button.button [
                                button.isLarge
                                color.isPrimary
                                prop.className "upload-file-button"

                                match model with
                                | FetchingDemoData -> prop.disabled true
                                | UploadingFile -> button.isLoading
                                | Idle
                                | FileReturnedWithWarnings _
                                | FileFailed _ -> ()

                                prop.children [
                                    Bulma.icon [
                                        Html.i [
                                            prop.className "fas fa-upload"
                                        ]
                                    ]
                                    Html.span [
                                        prop.text "Select data file"
                                    ]
                                    Bulma.fileInput [
                                        match model with
                                        | FetchingDemoData -> prop.disabled true
                                        | UploadingFile
                                        | Idle
                                        | FileReturnedWithWarnings _
                                        | FileFailed _ -> ()

                                        prop.onChange (fun
                                                           (files:
                                                               list<Browser.Types.File>) ->
                                            UploadFile files |> dispatch
                                        )
                                    ]
                                ]
                            ]
                        ]
                    ]
                ]
            ]
        ]
    ]

let private buttonArea model dispatch =
    // Note: Right now, each button has its own loader animation
    // Another way of doing it, would be to have a single loader being display
    // on top of the button area
    // Example: https://codepen.io/cssninjaStudio/pen/QoJwqK/
    React.fragment [
        useDemoDateButton model dispatch

        Bulma.text.div [
            size.isSize3
            text.hasTextCentered
            prop.text "or"
        ]

        uploadFileButton model dispatch
    ]

let private renderDestinationsNotFoundError (destinationsNotFound: CityState array) =
    if destinationsNotFound.Length = 0 then
        null
    else
        React.fragment [
            Bulma.text.p [
                prop.text
                    "The following destinations were not found:"
            ]
            Html.ul [
                yield! destinationsNotFound
                |> Array.map (fun destination ->
                    Html.li [
                        prop.text
                            (sprintf "%s, %s"
                                destination.City
                                destination.State
                            )
                    ]
                )
                |> Array.toList
            ]
        ]

let private renderFileReadErrors (errors : ParsingError array) =
    if errors.Length = 0 then
        null
    else
        React.fragment [
            Bulma.text.p [
                prop.text
                    "The following errors were found in the file:"
            ]
            Html.ul [
                yield! errors
                |> Array.map (fun error ->
                    let errorMessage =
                        match error.ErrorDetails with
                        | MissingCity -> "Missing City"
                        | MissingState -> "Missing State"
                        | MissingVolume -> "Missing Volume"
                        | NegativeVolume -> "Negative Volume"
                        | MalformedRow error -> "Malformed row: " + error

                    Html.li [
                        prop.text
                            $"Row %i{error.RowNumber}: %s{errorMessage}"
                    ]
                )
                |> Array.toList
            ]
        ]

let private renderErrors model dispatch =
    match model with
    | Idle
    | UploadingFile
    | FetchingDemoData -> null
    | FileFailed errorMessage ->
        Bulma.message [
            color.isDanger
            prop.children [
                Bulma.messageBody [
                    prop.className "no-border"
                    prop.text errorMessage
                ]
            ]
        ]
    | FileReturnedWithWarnings serverResponse ->
        Bulma.message [
            color.isWarning
            prop.children [
                Bulma.messageBody [
                    prop.className "no-border"
                    prop.children [
                        Bulma.content [
                            renderDestinationsNotFoundError serverResponse.DestinationsNotFound
                            renderFileReadErrors serverResponse.FileReadErrors

                            Bulma.text.div [
                                text.hasTextCentered
                                prop.children [
                                    Bulma.button.button [
                                        color.isWarning
                                        button.isMedium
                                        prop.text "Continue with only the valid rows"
                                        prop.onClick (fun _ -> dispatch ContinueWithValidRows)
                                    ]
                                ]
                            ]
                        ]
                    ]
                ]
            ]
        ]

let view model dispatch =
    Bulma.container [
        spacing.my5

        prop.children [
            Bulma.text.p [
                text.hasTextCentered
                size.isSize2
                prop.text "Optimizing Distribution Center Locations"
            ]

            Bulma.text.p [
                text.hasTextCentered
                size.isSize3
                text.hasTextWeightLight
                prop.text "via Greenfield Analysis"
            ]

            Html.br []

            Bulma.content [

                prop.style [
                    style.maxWidth (length.rem 70.0)
                    style.margin.auto
                ]

                prop.children [
                    Bulma.text.p [
                        prop.text "Optimizing distribution center (DC) locations is a vital step in supply chain planning that allows businesses to efficiently minimize their shipping costs, emissions, and service response times. Optimal Site App leverages a method called greenfield analysis to provide end users with recommended DC locations. Users only have to provide minimal information, including customer locations and demand volume, to produce the DC locations that best serve their customers' demand. Greenfield analysis accomplishes this by applying a clustering algorithm to user provided data. While the app easily creates these recommendations from scratch by default, it can also provide recommendations that take pinned DCs into account for users that may be looking to add new DCs to their existing or hypothetical network."
                        text.hasTextCentered
                        size.isSize4
                        text.hasTextWeightLight
                    ]
                ]
            ]

            Html.br []
            Html.br []

            buttonArea model dispatch

            Html.br []

            Bulma.content [

                prop.style [
                    style.maxWidth (length.rem 40.0)
                    style.margin.auto
                ]

                prop.children [
                    renderErrors model dispatch

                    Html.p
                        "You can analyze your own data by uploading a .csv file, listing the destinations you want to ship to and the corresponding volume."
                    Html.p "The .csv file should be formatted like so:"
                    Html.br []

                    Bulma.text.div [
                        // Mimic p behavior as pre cannot appear has descendant of p
                        spacing.mb4
                        prop.children [
                            Html.pre [
                                Html.code [
                                    prop.text
                                        """CITY,STATE,VOLUME
MILWAUKEE,WI,123.4
KNOXVILLE,TN,456.8
..."""
                                ]
                            ]
                        ]
                    ]

                    Html.p
                        "Once your shipment destinations and volumes have been uploaded, the app will identify Distribution Center locations that minimize the shipping distance."
                ]
            ]

            Html.br []
            Html.br []
            Html.br []
            Html.br []

            Bulma.text.p [
                text.hasTextCentered
                size.isSize4
                text.hasTextWeightLight
                prop.text "If you would like to stay up to date on this application and other SDI solutions, contact us below:"
            ]

            Html.br []

            Bulma.levelItem [
                Html.a [
                    prop.style [
                        style.cursor.pointer
                    ]
                    prop.children [
                        Bulma.button.button [
                            color.isPrimary
                            button.isMedium
                            prop.children [
                                Html.span [
                                    prop.text "Contact Us"
                                ]
                                Bulma.icon [
                                    Html.i [
                                        prop.className "fa-regular fa-envelope"
                                    ]
                                ]
                            ]
                        ]
                    ]
                    prop.target "_blank"
                    prop.href "https://simulationdynamics.com/contact-us"
                ]
            ]
        ]
    ]